<template>
    <div class="item-container">
        <div class="container">
            <b-row>
                <b-col
                    lg="4"
                    v-for="item in items"
                    :key="item.title"
                    class="item"
                >
                    <img :src="item.imgUrl" :alt="item.title" />
                    <div class="main">
                        <div class="title">{{ item.title }}</div>
                        <div class="content">
                            {{ item.content }}
                        </div>
                    </div>
                    <a :href="`mailto:${item.mail}`" class="mail">
                        <img
                            :src="
                                require('@/assets/images/general/mail-icon-2.svg')
                            "
                            alt="Mail"
                        />
                        <span>{{ item.mail }}</span>
                    </a>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Items',
    data() {
        return {
            items: [
                {
                    imgUrl: require('@/assets/images/contact/licensing.svg'),
                    title: 'Licensing',
                    content:
                        'For more information on how GoalSport can help to realize your company expansion plans, please contact',
                    mail: 'enquiries@goalsport.com',
                },
                {
                    imgUrl: require('@/assets/images/contact/legal.svg'),
                    title: 'Legal',
                    content: 'For all legal enquiries, please contact',
                    mail: 'legal@goalsport.com',
                },
                {
                    imgUrl: require('@/assets/images/contact/media.svg'),
                    title: 'Media',
                    content:
                        'For all media or press related enquiries, please contact',
                    mail: 'media@goalsport.com',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import './Items';
</style>

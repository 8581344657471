<template>
    <div class="process-container">
        <div class="container">
            <picture>
                <source
                    media="(max-width:991px)"
                    :srcset="
                        require('@/assets/images/about-us/process-mobile.png')
                    "
                />
                <img
                    :src="require('@/assets/images/about-us/process.png')"
                    alt="Goal"
                    class="process-img"
                />
            </picture>
            <div class="content">
                <div class="title">Processes</div>
                <div class="content">
                    <p>
                        GoalSport adopts the tried and tested "Engagement Model"
                        for the purpose of completing projects and launching new
                        sites. With this model, GoalSport employees are
                        motivated by certain objectives analogous to their
                        functions in the organization. The objectives comprise
                        goals and expectations that motivate and guide
                        employees.
                    </p>
                    <p>
                        Through the observance of the Engagement Model,
                        GoalSport employees continue to acquire strong software
                        development experience, project management knowledge,
                        intercultural competence, and a shared responsibility.
                        The model is variable with regard to a customer's
                        timeframes, confidentiality or security issues,
                        requirements dynamics or other factors. The Engagement
                        Model also guides the employees to clear, actionable,
                        relevant, flexible enterprise and project objectives.
                        The said model is appropriate for the sportsbook
                        software development industry where objectives need to
                        be simplified for easy and prompt completion of a
                        certain project.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Process',
};
</script>

<style lang="scss" scoped>
@import './Process';
</style>

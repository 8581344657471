<template>
    <div class="job-container">
        <div class="container">
            <div class="content">
                <div class="title">Job opportunities</div>
                <div class="content">
                    <p>
                        We are looking for highly motivated, dynamic, talented
                        and result-oriented individuals to join our growing
                        family. Candidates must be a team player, work well
                        under pressure and welcome everyday challenges in this
                        challenging industry of Information Technology. If you
                        want to work in an environment where you can realize
                        your potential, grow professionally and be part of an
                        innovative team, submit your detailed resume to us now
                        by emailing .. jobs@goalsport.com
                    </p>
                </div>
            </div>
            <picture>
                <source
                    media="(max-width:991px)"
                    :srcset="require('@/assets/images/about-us/job-mobile.png')"
                />
                <img
                    :src="require('@/assets/images/about-us/job.png')"
                    alt="Goal"
                    class="job-img"
                />
            </picture>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Job',
};
</script>

<style lang="scss" scoped>
@import './Job';
</style>

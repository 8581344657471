<template>
    <div class="services">
        <div class="title">
            <div class="container">Services</div>
        </div>
        <div class="container item-container">
            <div class="service-item">
                <div class="item-title">
                    Software Innovation, Development and Maintenance
                </div>
                <div class="item-content">
                    <p>
                        GoalSport is constantly upgrading and updating its core
                        GoalSport platform and ancilliary services and Licensees
                        will automatically benefit (at no additional charge)
                        from such enhancements.
                    </p>
                    <p>
                        GoalSport maintains dedicated 24/7 technical support and
                        customer support teams to ensure Licensee requirements
                        are met at all times. Licensees also receive timely
                        notices for any scheduled maintenance or system upgrades
                        and Customer Support teams are also in place to assist
                        with void bets, game suspensions, incorrect odds being
                        displayed or customer disputes.
                    </p>
                </div>
            </div>
            <div class="service-item">
                <div class="item-title">
                    Initial System design and Integration
                </div>
                <div class="item-content">
                    <p>
                        We believe a key element of GoalSport' services is the
                        ability to assist a potential Licensee in identifying
                        the most appropriate software and levels of services to
                        maximize revenues and business benefits. Our team of
                        experienced industry veterans can further examine your
                        overall gaming business as well as specific sportsbook
                        areas to determine how sports can best be implemented or
                        integrated.
                    </p>
                    <p>
                        We pride ourselves on our prompt and systematic
                        integrations and product launches and ensure that from
                        the moment a customer engages our services, the entire
                        GoalSport process is fully communicated at all times.
                    </p>
                </div>
            </div>
            <div class="service-item">
                <div class="item-title">Security</div>
                <div class="item-content">
                    <p>
                        Security and system integrity lie at the core of the
                        GoalSport platform and are present throughout the
                        physical infrastructure of the system as well as its
                        underlying user elements. Designed and built to the
                        highest internationally recognized standards applicable
                        to IT systems of this nature, GoalSport utilizes a
                        mixture of software, hardware and procedural guidelines
                        to protect its sportsbook systems - and underlying
                        customer betting data - from intrusion or unforeseen
                        events.
                    </p>
                    <p>
                        GoalSport also respects and understands the issues of
                        player confidentiality. Therefore we do not require any
                        player particulars and usually create a unique
                        identifier for each licensee's customers that link back
                        to the player details database on the operator side.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services',
};
</script>

<style lang="scss" scoped>
@import './Services';
</style>

import Vue from 'vue';
import App from './App.vue';
import { createRouter } from './router/router';
import helpers from './plugins/helpers';
import GlobalMixin from './mixins/global';
import { BootstrapVue } from 'bootstrap-vue';
import store from './store';
import VueScrollTo from 'vue-scrollto';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueScrollTo, {
    container: 'body',
    duration: 1000,
    easing: 'ease',
    offset: -70,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
});

const router = createRouter();

const app = new Vue({
    router,
    store,
    render: (h) => h(App),
    mixins: [GlobalMixin],
}).$mount('#app');

//plugins
Vue.prototype.$helpers = helpers(app);

<template>
    <div class="products">
        <div class="title">
            <div class="container">Products</div>
        </div>
        <Details />
        <Wallet />
        <Others />
    </div>
</template>

<script>
import Details from '@/components/products-services/Products/Details/Details';
import Others from '@/components/products-services/Products/Others/Others';
import Wallet from '@/components/products-services/Products/Wallet/Wallet';
export default {
    name: 'Products',
    components: { Wallet, Others, Details },
};
</script>

<style lang="scss" scoped>
@import './Products';
</style>

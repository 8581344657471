<template>
    <div class="home-about">
        <div class="container">
            <b-row>
                <b-col lg="4">
                    <div class="title">about us</div>
                    <div class="title-sub">GoalSport core competency</div>
                </b-col>
                <b-col lg="8">
                    <p>
                        is in the development and delivery of sportsbook
                        software services to gaming companies. GoalSport offers
                        several versions of its GoalSport platform and
                        associated services to Licensees looking to target
                        International, European or Asian markets. Its flexible
                        and competitive approach enables it to meet the
                        operational needs of its customers. GoalSport are also
                        extremely experienced in integrating with third party
                        providers or proprietary systems and pride ourselves on
                        our customer support and timely delivery of products and
                        services.
                    </p>
                    <p>
                        In addition to an extremely robust, flexible and secure
                        core sportsbetting platform that has evolved over the
                        past 10 years, GoalSport offers the market's leading
                        turnkey solution. Operators can effectively license a
                        fully managed sportsbook, with an extremely broad
                        selection of sports and the largest number of in-play
                        events and markets available anywhere in the industry.
                    </p>
                    <div class="event-container">
                        <div
                            v-for="event in events"
                            :key="event.name"
                            class="event-item"
                        >
                            <div class="event-separator"></div>
                            <div class="event-name">{{ event.name }}</div>
                            <div class="event-number">{{ event.number }}</div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeAbout',
    data() {
        return {
            events: [
                {
                    name: 'soccer events',
                    number: '5,000',
                },
                {
                    name: 'markets',
                    number: '18,000',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import './HomeAbout';
</style>

<template>
    <div class="wallet">
        <div class="container">
            <div class="info">
                <div class="content">
                    <div class="title">Single Wallet</div>
                    <p>
                        Single wallet simplifies the process for customer user
                        experience. GOALcasino is using solely on GoalSport
                        wallet, in another word, after customer transfers funds
                        to GOAlbook sportsbook wallet then customer is good to
                        go to play GOALcasino games. There will be no
                        troublesome procedures for customer to transferring back
                        and forth into different wallet in order to play
                        specific games.
                    </p>
                    <div class="title title-sub">Sports Currently Offered</div>
                    <p>
                        The listed sports opposite are currently offered and,
                        subject to licensee requirements, further sports can be
                        added on request.
                    </p>
                </div>
                <picture>
                    <source
                        media="(max-width:767px)"
                        :srcset="
                            require('@/assets/images/products-services/sketch-wallet-mobile.svg')
                        "
                    />
                    <img
                        :src="
                            require('@/assets/images/products-services/sketch-wallet.svg')
                        "
                        alt="Event 2"
                        class="info-img"
                    />
                </picture>
            </div>
            <div class="sports">
                <table>
                    <tr>
                        <td>Soccer</td>
                        <td>Diving</td>
                        <td>Snooker</td>
                    </tr>
                    <tr>
                        <td>American Football</td>
                        <td>Entertainment</td>
                        <td>Squash</td>
                    </tr>
                    <tr>
                        <td>Baseball</td>
                        <td>Equestrian</td>
                        <td>Swimming</td>
                    </tr>
                    <tr>
                        <td>Basketball</td>
                        <td>Field Hockey</td>
                        <td>Table Tennis</td>
                    </tr>
                    <tr>
                        <td>Archery</td>
                        <td>Golf</td>
                        <td>Tennis</td>
                    </tr>
                    <tr>
                        <td>Badminton</td>
                        <td>Gymnastics</td>
                        <td>Volleyball</td>
                    </tr>
                    <tr>
                        <td>Boxing</td>
                        <td>Water Polo</td>
                        <td>Canoeing</td>
                    </tr>
                    <tr>
                        <td>Ice Hockey</td>
                        <td>Weight Lifting</td>
                        <td>Cricket</td>
                    </tr>
                    <tr>
                        <td>Mix Martial Arts</td>
                        <td>Winter Sports</td>
                        <td>Darts</td>
                    </tr>
                    <tr>
                        <td>Motorsports</td>
                        <td>E-Sports</td>
                        <td></td>
                    </tr>
                    <tr class="blank-row">
                        <td>Seasonal Events</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Summer Olympics</td>
                        <td>Winter Olympics</td>
                        <td>Rugby World Cup</td>
                    </tr>
                </table>
            </div>
            <div class="currency">
                <div class="title">Odds Format</div>
                <div class="sub-title">
                    At the current time, GoalSport offers the following odds
                </div>
                <ul>
                    <li>American odds</li>
                    <li>UK (fractions)</li>
                    <li>Decimal</li>
                    <li>Hong Kong</li>
                    <li>Indonesian</li>
                    <li>Malaysian</li>
                </ul>
            </div>
            <div class="currency">
                <div class="title">Language Options</div>
                <div class="sub-title">Languages currently offered include</div>
                <ul>
                    <li>English</li>
                    <li>Chinese Simplified</li>
                    <li>Chinese Traditional</li>
                    <li>German</li>
                    <li>Hungarian</li>
                    <li>Indonesian</li>
                    <li>Italian</li>
                    <li>Japanese</li>
                    <li>Korean</li>
                    <li>Romanian</li>
                    <li>Russian</li>
                    <li>Thai</li>
                    <li>Vietnamese</li>
                    <li>Greek</li>
                    <li>Polish</li>
                    <li>Spanish</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Wallet',
};
</script>

<style lang="scss" scoped>
@import './Wallet';
</style>

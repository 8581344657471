import Home from '@/pages/Home/Home';
import AboutUs from '@/pages/AboutUs/AboutUs';
import ProductsServices from '@/pages/ProductsServices/ProductsServices';
import BetTypes from '@/pages/BetTypes/BetTypes';
import ContactUs from '@/pages/ContactUs/ContactUs';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs,
    },
    {
        path: '/products-services',
        name: 'products-services',
        component: ProductsServices,
    },
    {
        path: '/bet-types',
        name: 'bet-types',
        component: BetTypes,
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs,
    },
];

export default routes;

<template>
    <div class="footer">
        <div class="container d-block d-lg-flex justify-content-between">
            <div>
                <div>Term of Use</div>
                <div>GoalSport All rights reserved.</div>
            </div>
            <div class="mt-2">
                <a href="mailto:enquiries@goalsport.com">
                    <img
                        :src="require('@/assets/images/general/mail-icon.svg')"
                        alt="Mail"
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    methods: {},
};
</script>

<style lang="scss" scoped>
@import './Footer';
</style>

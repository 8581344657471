<template>
    <div>
        <Banner
            :scroll-id="'#products'"
            :sketch-url="
                require('@/assets/images/products-services/sketch-product.svg')
            "
            :sketch-mobile-url="
                require('@/assets/images/products-services/sketch-product-mobile.svg')
            "
        >
            <span class="text-primary">Products</span> & Services
        </Banner>
        <Products id="products" />
        <Services />
    </div>
</template>

<script>
import Banner from '@/components/utils/Banner/Banner';
import Products from '@/components/products-services/Products/Products';
import Services from '@/components/products-services/Services/Services';
export default {
    name: 'ProductsServices',
    components: { Services, Products, Banner },
};
</script>

<style lang="scss" scoped>
@import 'src/pages/ProductsServices/ProductsServices';
</style>

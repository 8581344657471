<template>
    <div class="soccer">
        <div class="container">
            <div class="title">Soccer</div>
            <table>
                <tr v-for="(item, i) in items" :key="i">
                    <td>{{ item.field1 }}</td>
                    <td>{{ item.field2 }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Soccer',
    data() {
        return {
            items: [
                {
                    field1: 'Full Time',
                    field2: '1st Half',
                },
                {
                    field1: 'Handicap',
                    field2: '1H Handicap',
                },
                {
                    field1: 'Over/Under',
                    field2: '1H Over/Under',
                },
                {
                    field1: '1X2',
                    field2: '1H 1X2',
                },
                {
                    field1: 'Next Corner',
                    field2: '1H Number of Corners',
                },
                {
                    field1: 'Number of Corners',
                    field2: '1H Home Team vs Away Team',
                },
                {
                    field1: 'Specific 15 mins Over/Under',
                    field2: '1H Home Team Over/Under',
                },
                {
                    field1: 'Specific 10 Minutes Over/Under',
                    field2: '1H Away Team Over/Under',
                },
                {
                    field1: 'Home Team vs Away Team',
                    field2: '1H Odd/Even',
                },
                {
                    field1: 'Home Team Over/Under',
                    field2: '1H Double Chance',
                },
                {
                    field1: 'Away Team Over/Under',
                    field2: '1H Correct Score',
                },
                {
                    field1: 'Odd/Even',
                    field2: '1H Total Goal',
                },
                {
                    field1: 'Correct Score',
                    field2: 'Exact 1H Goal',
                },
                {
                    field1: 'First Goal/Last Goal',
                    field2: '1H First Goal/Last Goal',
                },
                {
                    field1: 'HT/FT Odd/Even',
                    field2: '1H Winning Margin',
                },
                {
                    field1: 'Winning Margin',
                    field2: '1H Both Teams To Score',
                },
                {
                    field1: 'HT/FT',
                    field2: '2H Correct Score',
                },
                {
                    field1: 'Total Goal',
                    field2: '2H Odd/Even',
                },
                {
                    field1: 'Both/One/Neither Team To Score',
                    field2: '2H Handicap',
                },
                {
                    field1: '3-Way Handicap',
                    field2: '2H Over/Under',
                },
                {
                    field1: 'Clean Sheet',
                    field2: '2H Goal',
                },
                {
                    field1: 'Double Chance',
                    field2: '2H 1X2',
                },
                {
                    field1: 'Exact Total Goals',
                    field2: '2H Double Chance',
                },
                {
                    field1: 'Exact Home Team Goals',
                    field2: '2H Both Teams To Score',
                },
                {
                    field1: 'Exact Away Team Goals',
                    field2: 'Both Halves Over Yes/No',
                },
                {
                    field1: 'Result And First Team To Score',
                    field2: 'Both Halves Under Yes/No',
                },
                {
                    field1: 'Result/Total Goals',
                    field2: 'Home To Score In Both Halves',
                },
                {
                    field1: 'To Win To Nil',
                    field2: 'Away To Score In Both Halves',
                },
                {
                    field1: 'Half Time/Full Time Correct Score',
                    field2: 'Home To Win Both Halves',
                },
                {
                    field1: 'Both Team Score / Result',
                    field2: 'Away To Win Both Halves',
                },
                {
                    field1: 'Both Teams to Score/ Total Goal',
                    field2: 'Home To Win Either Half',
                },
                {
                    field1: 'Which Half First Goal',
                    field2: 'Away To Win Either Half',
                },
                {
                    field1: 'Home Team Which Half First Goal',
                    field2: 'Highest Scoring Half',
                },
                {
                    field1: 'Both Teams to Score/ Total Goal',
                    field2: 'Highest Scoring Half Home Team',
                },
                {
                    field1: 'Which Half First Goal',
                    field2: 'Highest Scoring Half Away Team',
                },
                {
                    field1: 'Home Team Which Half First Goal',
                    field2: 'Both Teams To Score in 1H / 2H',
                },
                {
                    field1: 'Away Team Which Half First Goal',
                    field2: 'Home 1H to Score/ 2H To Score',
                },
                {
                    field1: 'First Team 2 Goals',
                    field2: 'Away 1H to Score/ 2H To Score',
                },
                {
                    field1: 'First Team 3 Goals',
                    field2: 'Fantasy',
                },
                {
                    field1: 'Which Team to Kick-Off',
                    field2: 'Winner',
                },
                {
                    field1: 'Mix Parlay',
                    field2: 'Outright',
                },
                {
                    field1: 'Full Time Handicap',
                    field2: 'Full Time Total Bookings',
                },
                {
                    field1: 'Half Time Handicap',
                    field2: 'Half Time Total Bookings',
                },
                {
                    field1: 'Full Time Over / Under',
                    field2: 'Correct Score',
                },
                {
                    field1: 'Half Time Over / Under',
                    field2: 'Total Goals',
                },
                {
                    field1: 'Full Time Home Draw Away (1X2)',
                    field2: 'Half Time / Full Time',
                },
                {
                    field1: 'Half Time Home Draw Away (1X2)',
                    field2: 'First Goal / Last Goal',
                },
                {
                    field1: 'Full Time Number of Corners',
                    field2: 'Mix Parlay',
                },
                {
                    field1: 'Half Time Number of Corners',
                    field2: 'Outright',
                },
                {
                    field1: 'Specific 15 min Over / Under',
                    field2: 'Winner',
                },
                {
                    field1: 'Specific 10 min Over / Under',
                    field2: 'Which Team to Kick-off',
                },
                {
                    field1: 'Next Corner',
                    field2: 'Odd / Even',
                },
                {
                    field1: 'Single Team Over / Under',
                    field2: 'Fantasy Match',
                },
                {
                    field1: 'Number of Corner',
                    field2: 'Double Chance',
                },
                {
                    field1: 'Number of Throw in',
                    field2: 'Draw No Bet',
                },
                {
                    field1: 'Both/One/Neither to Score',
                    field2: 'To Win to Nil',
                },
                {
                    field1: '3 Ways handicap',
                    field2: 'Clean Sheet',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import './Soccer';
</style>

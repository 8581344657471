<template>
    <div class="container product-other" id="platform">
        <div class="other-item-container">
            <div class="other-item">
                <div class="title">Asian Format Sportsbook Platform</div>
                <div class="content">
                    <div class="item-img">
                        <picture>
                            <source
                                media="(max-width:767px)"
                                :srcset="
                                    require('@/assets/images/products-services/product-other-mobile.jpg')
                                "
                            />
                            <img
                                :src="
                                    require('@/assets/images/products-services/product-other.jpg')
                                "
                                alt="Event 2"
                            />
                        </picture>
                    </div>
                    <div class="item-content">
                        <p>
                            For International operators looking to expand their
                            brands into Asia, GoalSport offers a fully hosted
                            turnkey solution via certified third party solution
                            providers the look and feel are adapted to an
                            operator’s particular brand (or a new brand) with
                            all functionality, bet types and back end tools
                            required by the Asian marketplace immediately
                            implemented and available. Combining an
                            internationally recognized brand with a product that
                            offers the same range of in-play events offered by
                            Asia’s main sportsbooks, ensures a far greater
                            chance of success in the world’s largest and fastest
                            growing online betting market.
                        </p>
                        <p>
                            Product features that are common to both the Asian
                            formatted platform and International platform
                            include: – Admin User Access (User groups with
                            specific access available) – Risk Management Tools–
                            Extensive Reporting Tools– Player Administration
                            Tools– Event and Product controls– Wallet
                            Integration.
                        </p>
                    </div>
                </div>
            </div>
            <div class="other-item">
                <div class="title">China Format Sportsbook Platform</div>
                <div class="content">
                    <div class="item-img">
                        <picture>
                            <source
                                media="(max-width:767px)"
                                :srcset="
                                    require('@/assets/images/products-services/product-other-mobile.jpg')
                                "
                            />
                            <img
                                :src="
                                    require('@/assets/images/products-services/product-other.jpg')
                                "
                                alt="Event 2"
                                class="item-img"
                            />
                        </picture>
                    </div>
                    <div class="item-content">
                        <p>
                            China format is a completely new designed platform
                            which emphasized on the China market. It’s
                            particularly fine-tuned the user experience in
                            favors of Northern China market
                        </p>
                    </div>
                </div>
            </div>
            <div class="other-item">
                <div class="title">
                    International Format Sportsbook Platform
                </div>
                <div class="content">
                    <div class="item-img">
                        <picture>
                            <source
                                media="(max-width:767px)"
                                :srcset="
                                    require('@/assets/images/products-services/product-other-mobile.jpg')
                                "
                            />
                            <img
                                :src="
                                    require('@/assets/images/products-services/product-other.jpg')
                                "
                                alt="Event 2"
                            />
                        </picture>
                    </div>
                    <div class="item-content">
                        <p>
                            GoalSport also provide an international format
                            platform. In addition to the features mentioned
                            above, enhancements have been made to better serve
                            the European and international marketplace.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="other-info">
            <div class="title">Other Products</div>
            <ul>
                <li>
                    E-Sports betting on competitive video game is the fastest
                    growing sector in sports betting which is very popular with
                    a new sub-market of millennials.
                </li>
                <li>
                    GOALcasino offers over 85 games which support the latest
                    features such as multiple game variants and bonus rounds.
                </li>
                <li>
                    All the games are coming with high definition audio and
                    video interface combining with atmospheric lighting and
                    master control programs. GOALcasino content is available for
                    wide range of languages and currencies to ensure
                    unrestricted market accessibility.
                </li>
                <li>
                    Virtual sports are computer-generated matches which includes
                    soccer, horse racing, greyhounds, speedway, motorsports,
                    cycling and tennis.
                </li>
                <li>
                    Keno is played with 20 balls drawn randomly resulting from
                    80 numbered balls (01 to 80). The combination of these 20
                    numbers is divided and segregated into several variations
                    and different kinds of bet type.
                </li>
                <li>
                    Bet Aggregator is a risk mitigating service that helps our
                    customers to better deal with pro punters and to achieve a
                    profitable operation.
                </li>
                <li>
                    The GoalSport platform is already integrated with multiple
                    third party service vendors and statistical data providers.
                    Licensees will need to enter into direct agreement with the
                    relevant providers with the peace of mind that technical
                    integration is already in place.
                </li>
            </ul>
        </div>
        <div class="other-license">
            <div class="title">Licensing</div>

            <ul>
                <li>
                    GoalSport typically charges a low integration fee with
                    ongoing monthly fees based on gross win for licensing its
                    software and pricing solutions. Please contact our account
                    manager at licensing@goalsport.com for further details.
                </li>
                <li>
                    Mobile solution GoalSport offers a mobile betting interface
                    which is based on html5, currently available for the
                    iPhone™, Android™ and other smartphones for Sportsbooks
                    platform and Number Game. More products will integrate to
                    mobile betting interface in the near future.
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Others',
};
</script>

<style lang="scss" scoped>
@import './Others';
</style>

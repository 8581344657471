<template>
    <div class="container info-container">
        <div class="content">
            <div class="title">Others</div>
            <div class="title-sub">
                For all other enquiries, you can write or email us at the
                following:
            </div>
            <p>Isle Of Man Office</p>
            <p>RAFA House</p>
            <p>19, Bucks Rd</p>
            <p>Douglas,</p>
            <p>Isle of Man IM1 3DA</p>
            <a href="mailto:enquiries@goalsport.com" class="mail">
                <img
                    :src="require('@/assets/images/general/mail-icon-2.svg')"
                    alt="Mail"
                />
                <span>enquiries@goalsport.com</span>
            </a>
        </div>
        <picture>
            <source
                media="(max-width:991px)"
                :srcset="
                    require('@/assets/images/contact/other-img-mobile.svg')
                "
            />
            <img
                :src="require('@/assets/images/contact/other-img.svg')"
                alt="Tower"
                class="info-img"
            />
        </picture>
    </div>
</template>

<script>
export default {
    name: 'Info',
};
</script>

<style lang="scss" scoped>
@import './Info';
</style>

<template>
    <div class="banner">
        <div class="title"><slot></slot></div>

        <a href="#" v-scroll-to="scrollId" class="scroll-mark">
            <img
                :src="require('@/assets/images/general/arrow-down-icon.svg')"
                alt="Scroll down"
            />
        </a>

        <picture>
            <source media="(max-width:1198px)" :srcset="sketchMobileUrl" />
            <img :src="sketchUrl" alt="sketch" class="sketch-img" />
        </picture>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    props: {
        scrollId: {
            type: String,
            required: true,
        },
        sketchUrl: {
            type: String,
            required: true,
        },
        sketchMobileUrl: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import './Banner';
</style>

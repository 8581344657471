<template>
    <div>
        <Banner
            :scroll-id="'#aboutInfo'"
            :sketch-url="
                require('@/assets/images/about-us/sketch-about-us.svg')
            "
            :sketch-mobile-url="
                require('@/assets/images/about-us/sketch-about-us-mobile.svg')
            "
        >
            <span class="text-primary">About</span> us
        </Banner>
        <Info id="aboutInfo" />
        <Gallery />
        <Process />
        <Job />
    </div>
</template>

<script>
import Banner from '@/components/utils/Banner/Banner';
import Info from '@/components/about-us/Info/Info';
import Gallery from '@/components/about-us/Gallery/Gallery';
import Process from '@/components/about-us/Process/Process';
import Job from '@/components/about-us/Job/Job';

export default {
    name: 'AboutUs',
    components: { Job, Process, Gallery, Info, Banner },
    methods: {},
};
</script>

<style lang="scss" scoped>
@import './AboutUs';
</style>

<template>
    <div class="d-flex flex-column min-vh-100">
        <Header />
        <div class="flex-grow-1">
            <router-view />
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/layout/Header/Header';
import Footer from '@/components/layout/Footer/Footer';

export default {
    components: { Header, Footer },
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>

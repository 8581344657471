<template>
    <div class="home-info container">
        <b-row>
            <b-col v-for="(item, i) in items" :key="i" lg="4">
                <div
                    class="home-info-item"
                    @click="() => goto(item.path, item.hash)"
                >
                    <picture>
                        <source
                            media="(max-width:991px)"
                            :srcset="item.srcMobile"
                        />
                        <img
                            :src="item.srcWeb"
                            :alt="item.name"
                            class="item-img"
                        />
                    </picture>
                    <div class="item-name">{{ item.name }}</div>
                    <div class="item-content">
                        {{ item.content }}
                    </div>
                    <div class="item-link">
                        <img
                            :src="
                                require('@/assets/images/general/arrow-right-icon.svg')
                            "
                            alt="Go to"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'HomeInfo',
    data() {
        return {
            items: [
                {
                    srcWeb: require('@/assets/images/home/pc.svg'),
                    srcMobile: require('@/assets/images/home/pc-mobile.svg'),
                    name: 'Products and Services',
                    content:
                        'Covering the entire spectrum of bookmaking, our core betting platform is a proven, robust system offering an extensive range of events, bet types and multiple odds offerings and languages.',
                    path: 'products-services',
                    hash: '',
                },
                {
                    srcWeb: require('@/assets/images/home/light.svg'),
                    srcMobile: require('@/assets/images/home/light-mobile.svg'),
                    name: 'Events and Odds Solutions',
                    content:
                        'GoalSport via its solution providers offers one of the largest, if not the largest selection of in-play events.',
                    path: 'products-services',
                    hash: '#inplay',
                },
                {
                    srcWeb: require('@/assets/images/home/pc-2.svg'),
                    srcMobile: require('@/assets/images/home/pc-2-mobile.svg'),
                    name: 'Localized Platforms',
                    content:
                        'Purpose built platforms for International Markets and End-to-End solutions for Western Operators looking for fast access to Asian markets.',
                    path: 'products-services',
                    hash: '#platform',
                },
            ],
        };
    },
    methods: {
        goto(path, hash) {
            this.$router.push({
                path,
                hash,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import './HomeInfo';
</style>

<template>
    <div class="home-slider">
        <div class="home-carousel">
            <b-carousel
                id="headerCarousel"
                v-model="slide"
                :interval="5000"
                indicators
                fade
                background="#111111"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
            >
                <b-carousel-slide v-for="(image, i) in images" :key="i">
                    <div class="caption-container">
                        <div class="caption-title">{{ image.title }}</div>
                        <div class="caption-description">
                            {{ image.description }}
                        </div>
                    </div>
                    <template #img>
                        <div
                            class="carousel-img-container"
                            :style="`background-image: url('${require('@/assets/images/' +
                                image.url)}')`"
                        >
                            <img
                                :src="require('@/assets/images/' + image.url)"
                                class="carousel-img"
                                width="1024"
                                height="480"
                                :alt="image.title"
                            />
                        </div>
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </div>
    </div>
</template>

<script>
import { IMAGE_TYPES } from '@/constants/image';

export default {
    name: 'HomeSlider',
    data() {
        return {
            slide: 0,
            sliding: null,
            images: [
                {
                    title: 'Robust Platform.',
                    description:
                        'More than 10 years of continual development, testing and product enhancement has resulted in market leading platform stability and scalability.',
                    url: 'home/working-banner.jpg',
                },
                {
                    title: 'Robust Platform.',
                    description:
                        'More than 10 years of continual development, testing and product enhancement has resulted in market leading platform stability and scalability.',
                    url: 'home/chair-banner.jpg',
                },
            ],
        };
    },
    methods: {
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        },
        getImageType(src) {
            const splitSrc = src.split('.');
            const extension = splitSrc[splitSrc.length - 1] || '';
            return IMAGE_TYPES[extension.toUpperCase()]?.type;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './HomeSlider';
</style>

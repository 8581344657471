<template>
    <div class="gallery">
        <div class="container">
            <b-row class="item-container">
                <b-col v-for="image in images" :key="image.name" lg="4">
                    <div class="item">
                        <picture>
                            <source
                                media="(max-width:575px)"
                                :srcset="image.srcMobile"
                            />
                            <img
                                :src="image.srcWeb"
                                :alt="image.name"
                                class="item-img"
                            />
                        </picture>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Gallery',
    data() {
        return {
            images: [
                {
                    srcWeb: require('@/assets/images/about-us/home-1.jpg'),
                    srcMobile: require('@/assets/images/about-us/home-1-mobile.jpg'),
                    name: 'Home-1',
                },
                {
                    srcWeb: require('@/assets/images/about-us/home-2.jpg'),
                    srcMobile: require('@/assets/images/about-us/home-2-mobile.jpg'),
                    name: 'Home-2',
                },
                {
                    srcWeb: require('@/assets/images/about-us/home-3.jpg'),
                    srcMobile: require('@/assets/images/about-us/home-3-mobile.jpg'),
                    name: 'Home-3',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import './Gallery';
</style>

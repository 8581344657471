<template>
    <div class="others">
        <div class="container">
            <div v-for="game in games" :key="game.title" class="game-item">
                <div class="title">{{ game.title }}</div>
                <table>
                    <tr
                        v-for="(item, i) in game.items"
                        :key="i"
                        :class="{ 'blank-row': item.isBlank }"
                    >
                        <td v-if="item.field1 !== null">{{ item.field1 }}</td>
                        <td v-if="item.field2 !== null">{{ item.field2 }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Others',
    data() {
        return {
            games: [
                {
                    title: 'E-Sports',
                    items: [
                        {
                            isBlank: true,
                            field1: 'Dota2',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: false,
                            field1: 'First blood',
                            field2: '1st To 10 Kills',
                        },
                        {
                            isBlank: false,
                            field1: 'Total Kills Moneyline',
                            field2: 'Total Kills Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Total Kills Over / Under',
                            field2: 'Total Kills Odd Even',
                        },
                        {
                            isBlank: false,
                            field1: '1st Roshan',
                            field2: '1st Tower',
                        },
                        {
                            isBlank: false,
                            field1: '1st Barrack Player',
                            field2: 'Kills Head to Head',
                        },
                        {
                            isBlank: false,
                            field1: 'In-Play Map Moneyline',
                            field2: 'In-Play 1st Ten Kills',
                        },
                        {
                            isBlank: true,
                            field1: 'League Of Legends',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: false,
                            field1: 'First blood',
                            field2: '1st To 5 Kills',
                        },
                        {
                            isBlank: false,
                            field1: '1st To 10 Kills',
                            field2: 'Total Kills Moneyline',
                        },
                        {
                            isBlank: false,
                            field1: 'Total Kills Handicap',
                            field2: 'Total Kills Over / Under',
                        },
                        {
                            isBlank: false,
                            field1: 'Total Kills Odd Even',
                            field2: '1st Barron',
                        },
                        {
                            isBlank: false,
                            field1: '1st Turret Player',
                            field2: 'Kills Head to Head',
                        },
                        {
                            isBlank: false,
                            field1: 'In-Play Map Moneyline',
                            field2: 'In-Play Kills Markets',
                        },
                        {
                            isBlank: true,
                            field1: 'Counter-Strike',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: false,
                            field1: 'Map Handicap',
                            field2: 'Map Over / Under',
                        },
                        {
                            isBlank: false,
                            field1: '1st Round',
                            field2: '16th Round',
                        },
                        {
                            isBlank: false,
                            field1: '1st to 5 Rounds',
                            field2: 'In-Play Map Moneyline',
                        },
                        {
                            isBlank: false,
                            field1: 'In-Play Map HDP',
                            field2: 'In-Play Map OU',
                        },
                        {
                            isBlank: true,
                            field1: 'Starcraft',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: true,
                            field1: 'King of Glory',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: true,
                            field1: 'Warcraft 3',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: true,
                            field1: 'Overwatch',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: true,
                            field1: 'Heros of the Storm',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: true,
                            field1: 'Heathstone',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: true,
                            field1: 'Street Fighter V',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Moneyline',
                            field2: 'Match Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Over / Under',
                            field2: 'Map Moneyline',
                        },
                        {
                            isBlank: true,
                            field1: 'Player Unknown Battlegrounds',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Match Outright',
                            field2: 'Map Outright',
                        },
                    ],
                },
                {
                    title: 'Basketball',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Handicap',
                            field2: 'Most 3 Points shots scores Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Over / Under',
                            field2: 'Most 3 Points shots scores Over / Under',
                        },
                        {
                            isBlank: false,
                            field1: 'Odd / Even',
                            field2: 'Highest Scoring Quarter Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Most Points Scored Handicap',
                            field2: 'Most Rebounds Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Most Points Scored Over / Under',
                            field2: 'Most Rebounds Over / Under',
                        },
                        {
                            isBlank: false,
                            field1: 'Which Player to score the 1st basket Handicap',
                            field2: 'Which Player to score the last basket Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Which Team Race to the first 20 points Handicap',
                            field2: 'Fantasy Matches Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Fantasy Matches Over / Under',
                            field2: 'Fantasy Matches Odd / Even',
                        },
                        {
                            isBlank: false,
                            field1: 'Which Team to score the 1st basket Handicap',
                            field2: 'Which Team to score the last basket Handicap',
                        },
                    ],
                },
                {
                    title: 'Baseball',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Full Time Money Line, Handicap, Over / Under',
                            field2: 'Outright',
                        },
                        {
                            isBlank: false,
                            field1: 'First Half Money Line, Handicap',
                            field2: 'Mix Parlay',
                        },
                        {
                            isBlank: false,
                            field1: 'First Half Over / Under',
                            field2: '',
                        },
                    ],
                },
                {
                    title: 'American Football',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Handicap, Over / Under, Odd / Even',
                            field2: '1st, 2nd, 3rd, 4th Quarter Handicap, Over / Under (only playoffs)',
                        },
                        {
                            isBlank: false,
                            field1: 'Which team to score the 1st points Handicap',
                            field2: 'Which team to score the last points Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Origin of SuperBowl XLV winner Handicap',
                            field2: '',
                        },
                    ],
                },
                {
                    title: 'Golf',
                    items: [
                        {
                            isBlank: true,
                            field1: 'Majors',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Outright winner & Top 10 Finish',
                            field2: '72 Hole H2H',
                        },
                        {
                            isBlank: false,
                            field1: 'Favourite versus the Field',
                            field2: '18 Hole H2H',
                        },
                        {
                            isBlank: false,
                            field1: '18 Hole Player Over / Under',
                            field2: '',
                        },
                        {
                            isBlank: true,
                            field1: 'All Other Tournaments',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Outright Winner and Favourite versus the Field',
                            field2: '72 Hole H2H and 18 Hole H2H',
                        },
                    ],
                },
                {
                    title: 'Motorsports',
                    items: [
                        {
                            isBlank: true,
                            field1: 'Formula One',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Race Outright Winner, Podium and Points Finish',
                            field2: 'Game Over / Under and Odd / Even',
                        },
                        {
                            isBlank: false,
                            field1: 'Race H2H and Race Fastest Lap H2H',
                            field2: 'Most Aces',
                        },
                        {
                            isBlank: false,
                            field1: 'Qualifying – Outright Winner and H2H',
                            field2: '',
                        },
                        {
                            isBlank: true,
                            field1: 'Moto GP',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Race Outright Winner and Podium Finish',
                            field2: 'Set Handicap',
                        },
                    ],
                },
                {
                    title: 'Tennis',
                    items: [
                        {
                            isBlank: true,
                            field1: 'Grand Slams',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Game Over / Under and Odd / Even',
                        },
                        {
                            isBlank: false,
                            field1: 'Set and Game Handicap',
                            field2: 'Most Aces',
                        },
                        {
                            isBlank: true,
                            field1: 'All Other Tournaments',
                            field2: '',
                        },
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Set Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Game Handicap',
                            field2: '',
                        },
                    ],
                },
                {
                    title: 'Athletics',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: null,
                        },
                    ],
                },
                {
                    title: 'Badminton',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Over / Under',
                            field2: 'Odd / Even',
                        },
                        {
                            isBlank: false,
                            field1: 'Outright',
                            field2: 'Mix Parlay',
                        },
                    ],
                },
                {
                    title: 'Billiards',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Over / Under (World Snooker Tournament Only)',
                            field2: 'Odd / Even',
                        },
                        {
                            isBlank: false,
                            field1: 'Outright',
                            field2: 'Mix Parlay',
                        },
                    ],
                },
                {
                    title: 'Boxing',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Outright',
                        },
                        {
                            isBlank: false,
                            field1: 'Mix Parlay',
                            field2: '',
                        },
                    ],
                },
                {
                    title: 'Cricket (Test, ODI, T20s and IPL)',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Outright',
                        },
                        {
                            isBlank: false,
                            field1: 'Handicap',
                            field2: '1x2',
                        },
                    ],
                },
                {
                    title: 'Darts',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Most 180s',
                        },
                        {
                            isBlank: false,
                            field1: 'Handicap',
                            field2: '',
                        },
                    ],
                },
                {
                    title: 'Field Hockey',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Handicap',
                            field2: 'Over / Under',
                        },
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Outright',
                        },
                        {
                            isBlank: false,
                            field1: 'Mix Parlay',
                            field2: '',
                        },
                    ],
                },
                {
                    title: 'Handball',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Over / Under',
                            field2: 'Odd / Even',
                        },
                    ],
                },
                {
                    title: 'Ice Hockey',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Over / Under',
                            field2: '1st Period Handicap (Playoffs Only)',
                        },
                        {
                            isBlank: false,
                            field1: '1st Period Over / Under (Playoffs Only)',
                            field2: '2nd Period Handicap (Playoffs Only)',
                        },
                        {
                            isBlank: false,
                            field1: '2nd Period Over / Under (Playoffs Only)',
                            field2: '',
                        },
                    ],
                },
                {
                    title: 'MMA / UFC',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: null,
                        },
                    ],
                },
                {
                    title: 'Olympic - Summer',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Set Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Outright',
                            field2: 'Over / Under',
                        },
                        {
                            isBlank: false,
                            field1: 'Mix Parlay',
                            field2: 'Odd / Even',
                        },
                    ],
                },
                {
                    title: 'Olympic - Winter',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Set Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Outright',
                            field2: 'Over / Under',
                        },
                        {
                            isBlank: false,
                            field1: 'Mix Parlay',
                            field2: 'Odd / Even',
                        },
                    ],
                },
                {
                    title: 'Swimming',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Outright',
                        },
                    ],
                },
                {
                    title: 'Table Tennis',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Handicap',
                        },
                    ],
                },
                {
                    title: 'Volleyball',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Handicap',
                        },
                        {
                            isBlank: false,
                            field1: 'Over / Under',
                            field2: 'Odd / Even',
                        },
                    ],
                },
                {
                    title: 'Water Polo',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Handicap',
                            field2: 'Over / Under',
                        },
                    ],
                },
                {
                    title: 'Squash',
                    items: [
                        {
                            isBlank: false,
                            field1: 'Moneyline',
                            field2: 'Set Handicap',
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import './Others';
</style>

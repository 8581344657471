<template>
    <div class="container info-container">
        <div class="content">
            <div class="title">About Us</div>
            <div class="content">
                <p>
                    GoalSport is a market leading sportsbook platform provider
                    that has evolved over the past 10 + years to be the chosen
                    supplier to some of the largest sportsbooks in the world.
                    GoalSport' core technology and programming team of 200 are
                    based in Taiwan with a shortly to be opened European office
                    being established to further support our European customers.
                </p>
                <p>
                    Due to its Asian origins, where in-running betting is
                    prevalent, GoalSport offers an unparalleled range of in-play
                    events and markets. This is an ever more important factor to
                    sportsbook operators as in-running betting popularity
                    increases globally. Utilising GoalSport allows operators to
                    offer a full menu of in-play events without having to
                    recruit expensive, resource-intensive trading teams. We look
                    to provide a highly resilient Best of Breed products that
                    will allow operators to better retain customers and maximize
                    profitability from sportsbetting operations. We provide
                    outstanding technical customer support, and software
                    services to our customers.
                </p>
            </div>
        </div>
        <picture>
            <source
                media="(max-width:991px)"
                :srcset="require('@/assets/images/about-us/goal-mobile.png')"
            />
            <img
                :src="require('@/assets/images/about-us/goal.png')"
                alt="Goal"
                class="info-img"
            />
        </picture>
    </div>
</template>

<script>
export default {
    name: 'Info',
};
</script>

<style lang="scss" scoped>
@import './Info';
</style>

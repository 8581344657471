<template>
    <div class="header-sidebar">
        <b-button v-b-toggle.headerSidebar class="btn-img">
            <img
                :src="require('@/assets/images/general/menu-icon.png')"
                alt="menu icon"
            />
        </b-button>
        <b-sidebar id="headerSidebar" class="header-sidebar" right>
            <template #header="{ hide }">
                <div
                    class="
                        action-container
                        container
                        d-flex
                        justify-content-end
                    "
                >
                    <b-button class="btn-img" size="sm" @click="hide">
                        <img
                            :src="
                                require('@/assets/images/general/close-icon.svg')
                            "
                            alt="Close"
                        />
                    </b-button>
                </div>
            </template>
            <div class="link-container">
                <div v-for="link in links" :key="link.name" class="link-item">
                    <router-link
                        :to="{ name: link.name }"
                        :class="{
                            'router-link-exact-active':
                                $route.name === link.name,
                        }"
                    >
                        {{ link.label }}
                    </router-link>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
export default {
    name: 'HeaderSidebar',
    props: {
        links: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import './HeaderSidebar';
</style>

import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

export function createRouter() {
    return new Router({
        mode: 'history',
        routes: routes,
        scrollBehavior: async function (to, from) {
            if (to.hash) {
                return {
                    selector: to.hash,
                    offset: { x: 0, y: 70 },
                };
            }
            return { x: 0, y: 0 };
        },
    });
}

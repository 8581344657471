<template>
    <div class="products">
        <div class="product-info container">
            <div class="info-content">
                <div class="info-item">
                    <div class="info-title">GoalSport</div>
                    <p>
                        Our principal offering, the GoalSport’ sports betting
                        platform is a proven, robust system offering an
                        extensive range of events, bet types and multiple odds,
                        offerings and languages. Written in asp.net 2.0, it also
                        offers a powerful, fully-featured back end, can be
                        tailored for either the Asian or International
                        marketplace, and is even being developed for a range of
                        betting shop terminals.
                    </p>
                </div>

                <div class="info-item">
                    <div class="info-title">Events Creation and Pricing</div>
                    <p>
                        In conjunction with dedicated solution providers,
                        GoalSport offers an extremely competitive turnkey
                        solution with auto-event creation in over 40 sporting
                        sectors and event pricing with multiple bet types.
                    </p>
                </div>
                <div class="info-item">
                    <div class="info-title">XML Event and Price Feeds</div>
                    <p>
                        In conjunction with the sportsbook platform, GoalSport
                        offers a variety of two-way pricing and betting feeds
                        for both marketing purposes and very selective
                        sportsbook operators.
                    </p>
                </div>
                <div class="info-item">
                    <div class="info-title">Flexible Customisation Options</div>
                    <p>
                        We understand the different needs of our customers and
                        pride ourselves in our ability to deliver cost effective
                        solutions in a timely manner. The GoalSport platform
                        offers a significant number of customisable features and
                        display options allowing licensees to create their ideal
                        platform either as a standalone sports offering or
                        integrated with their other products.
                    </p>
                </div>
            </div>
            <picture>
                <source
                    media="(max-width:767px)"
                    :srcset="
                        require('@/assets/images/products-services/product-info-mobile.png')
                    "
                />
                <img
                    :src="
                        require('@/assets/images/products-services/product-info.png')
                    "
                    alt="Product Info"
                    class="info-img"
                />
            </picture>
        </div>
        <div class="product-events" id="inplay">
            <div class="container">
                <div class="event-img-container">
                    <picture>
                        <source
                            media="(max-width:575px)"
                            :srcset="
                                require('@/assets/images/products-services/product-event-1-mobile.jpg')
                            "
                        />
                        <img
                            :src="
                                require('@/assets/images/products-services/product-event-1.jpg')
                            "
                            alt="Event 1"
                            class="event-img"
                        />
                    </picture>
                    <picture>
                        <source
                            media="(max-width:575px)"
                            :srcset="
                                require('@/assets/images/products-services/product-event-2-mobile.jpg')
                            "
                        />
                        <img
                            :src="
                                require('@/assets/images/products-services/product-event-2.jpg')
                            "
                            alt="Event 2"
                            class="event-img"
                        />
                    </picture>
                </div>
                <div class="event-info">
                    <div class="title">
                        In-play Events and Betting Liquidity
                    </div>
                    <p>
                        GoalSport, via its dedicated solution providers, offers
                        one of the largest, if not the largest selection of
                        in-play events to its licensees. In a typical mid-season
                        month, an operator can offer its customers more than 100
                        soccer leagues with more than 5,000 in-play events and
                        18,000 in-play markets per month. Outside of soccer, a
                        further 1,800 in-play events and 3,000 in-play markets
                        are available on a monthly basis with more in-play
                        markets established during periodic/seasonal events such
                        as the Olympics, Winter Olympics, and the cricket,
                        rugby, and soccer world cups.
                    </p>
                    <p>
                        Only by leveraging an enormous pool of betting
                        liquidity, fueled at peak times by tens of thousands of
                        concurrent bettors, managed by expert trading teams of
                        some 700 traders, would it be possible for a gaming
                        company to have an in-play betting offering with
                        thousands of in-play events, competitive pricing and
                        allowing far larger than normal bets to be placed at any
                        one price. GoalSport licensees benefit from this exactly
                        advantage technology meets human instinct. Whilst other
                        providers only offer automated sportsbooks, we have more
                        than 700 experienced traders, working 24/7, monitoring
                        up to hundreds of live events at any one time
                        complemented by an extensively developed, proprietary
                        algorithm.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Details',
};
</script>

<style lang="scss" scoped>
@import './Details';
</style>

<template>
    <div class="home">
        <HomeSlider class="home-slider" />
        <div class="content-container">
            <HomeAbout />
            <HomeInfo />
        </div>
    </div>
</template>

<script>
import HomeSlider from '@/components/home/HomeSlider/HomeSlider';
import HomeAbout from '@/components/home/HomeAbout/HomeAbout';
import HomeInfo from '@/components/home/HomeInfo/HomeInfo';
export default {
    name: 'Home',
    components: { HomeInfo, HomeAbout, HomeSlider },
    methods: {},
};
</script>

<style lang="scss" scoped>
@import './Home';
</style>

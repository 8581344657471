<template>
    <div>
        <Banner
            :scroll-id="'#betTypeInfo'"
            :sketch-url="
                require('@/assets/images/bet-types/sketch-bettype.svg')
            "
            :sketch-mobile-url="
                require('@/assets/images/bet-types/sketch-bettype-mobile.svg')
            "
        >
            <span class="text-primary">Bet</span> Types
        </Banner>
        <div class="bettype-info" id="betTypeInfo">
            <div class="container">
                Please note this list only contains bet types for major sports
                and is subject to constant review. Licensees should check with
                their Account Manager for current sports and bet types as well
                as for all other available sports.
            </div>
        </div>
        <Soccer />
        <Others />
    </div>
</template>

<script>
import Banner from '@/components/utils/Banner/Banner';
import Soccer from '@/components/bet-types/Soccer/Soccer';
import Others from '@/components/bet-types/Others/Others';

export default {
    name: 'BetTypes',
    components: { Others, Soccer, Banner },
    methods: {},
};
</script>

<style lang="scss" scoped>
@import './BetTypes';
</style>

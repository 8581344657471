<template>
    <div class="header" :class="{ show: showHeader }">
        <div class="container header-content">
            <router-link :to="{ name: 'home' }">
                <picture>
                    <source
                        media="(max-width:991px)"
                        :srcset="
                            require('@/assets/images/header/logo-header-mobile.png')
                        "
                    />
                    <img
                        :src="require('@/assets/images/header/logo-header.png')"
                        class="header-logo"
                        alt="GoalSport"
                    />
                </picture>
            </router-link>
            <div class="link-container">
                <router-link
                    v-for="link in links"
                    :key="link.name"
                    :to="{ name: link.name }"
                    :class="{
                        'router-link-exact-active': $route.name === link.name,
                    }"
                >
                    {{ link.label }}
                </router-link>
            </div>
            <HeaderSidebar :links="links" class="header-sidebar" />
        </div>
    </div>
</template>

<script>
import HeaderSidebar from '@/components/layout/Header/HeaderSidebar/HeaderSidebar';
export default {
    name: 'Header',
    components: { HeaderSidebar },
    data() {
        return {
            showHeader: false,
            links: [
                {
                    name: 'home',
                    label: 'Home',
                },
                {
                    name: 'about-us',
                    label: 'About Us',
                },
                {
                    name: 'products-services',
                    label: 'Products & Services',
                },
                {
                    name: 'bet-types',
                    label: 'Bet types',
                },
                {
                    name: 'contact-us',
                    label: 'Contact Us',
                },
            ],
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        return () => {
            window.removeEventListener('scroll', this.handleScroll);
        };
    },
    methods: {
        handleScroll() {
            window.scrollY > 0
                ? (this.showHeader = true)
                : (this.showHeader = false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import './Header';
</style>

export const IMAGE_TYPES = {
    PNG: {
        ext: 'png',
        type: 'image/png',
    },
    JPG: {
        ext: 'jpg',
        type: 'image/jpeg',
    },
    JPEG: {
        ext: 'jpeg',
        type: 'image/jpeg',
    },
    GIF: {
        ext: 'gif',
        type: 'image/gif',
    },
    WEBP: {
        ext: 'webp',
        type: 'image/webp',
    },
    SVG: {
        ext: 'svg',
        type: 'image/svg+xml',
    },
};

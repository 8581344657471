<template>
    <div>
        <Banner
            :scroll-id="'#contactItems'"
            :sketch-url="require('@/assets/images/contact/sketch-contact.svg')"
            :sketch-mobile-url="
                require('@/assets/images/contact/sketch-contact-mobile.svg')
            "
        >
            <span class="text-primary">Contact</span> us
        </Banner>
        <Items id="contactItems" />
        <Info />
    </div>
</template>

<script>
import Banner from '@/components/utils/Banner/Banner';
import Items from '@/components/contact-us/Items/Items';
import Info from '@/components/contact-us/Info/Info';

export default {
    name: 'ContactUs',
    components: { Info, Items, Banner },
    methods: {},
};
</script>

<style lang="scss" scoped>
@import './ContactUs';
</style>
